<!--最大化最小化组件封装-->
<template>
  <div class="header-title" style="padding: 10px;">
      <span class="title-name">{{ dialogTittle }}</span>
      <span 
          style="float: right; color: white; cursor: pointer;" 
          class="el-icon-close" 
          @click="closed"></span>
      <span 
          style="float: right; margin-right: 10px; color:white; cursor: pointer;" 
          :class="flodIconClass"
          @click="() => isFullscreen"></span>
  </div>
</template>

<script>
export default {
  props: {
    dialogTittle: {
        type: String,
        default: () => ''
    },
    fullscreen: {
        type: Boolean,
        default: () => false
    }
  },
  data () {
    return {
      header_box: {
          'display': 'flex',
          'align-items': 'center'
      }
    }
  },
  computed: {
      flodIconClass() {
          return this.fullscreen ? 'el-icon-full-screen' : 'el-icon-copy-document';
      },
      isFullscreen() { 
          this.$emit('is-fullscreen', !this.fullscreen);
		  
          this.$emit('h_change',!this.fullscreen);
      },
     
  },
  created() {
  
  },
  methods: {
      closed() {  
          this.$emit('handle-closed', 2)
      }
  },
  mounted() {
    
  },
}
</script>